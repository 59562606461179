import React, { useState, useEffect } from 'react'
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import config from 'config/common'
import lib from 'lib/commons'

import ModalRule from 'components/ModalRule'

const Header = ({
  lng,
  currentUser: {
    user = {}
  } = {},
}) => {

  const parsed = queryString.parse(location.search)
  if(parsed.utm_source == 'client') {
    localStorage.setItem('is-ingame', '1')
  }

  const [modalStatus, setModalStatus] = useState(false)
  const closeModal = () => setModalStatus(false)

  return (
    <>
      <div id="header">
        <div className="top-menu">
          <NavLink to="/home" className="logo"><img src="/images/logo.png" alt=""/></NavLink>
        </div>
        <div className="login-area">
          <NavLink to="/home">
            Partner program
          </NavLink>
          <span className="line"></span>
          <NavLink to="/content-creator">
            Content creator
          </NavLink>
          {!user.id ? (
            <a href="#" onClick={event => {
              event.preventDefault()
              lib.showLogin()
            }} className="login-url">Login</a>
          ) : (
            <>
              
              <span className="login-url logged"><span className="user-name">Hi {user.nickname}</span> <a href="/user/logout"><img src="/images/icon-logout.png" alt="" className="icon-logout" /></a></span>
            </>
          )}
          <a href="#" className="icon-faq" onClick={e => {
            e.preventDefault()
            setModalStatus(true)
          }}><img src="/images/faq.png" alt="" /></a>
        </div>
      </div>

      {modalStatus && (          
        <ModalRule
          lng={lng}
          modalStatus={modalStatus}
          closeModal={closeModal}
        />
      )}
    </>
  )
}

export default Header