import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import lang from "lng/index"
const lng = lang[config.lng]

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const METADATA_REQUEST         = 'METADATA_REQUEST'
export const METADATA_REQUEST_ERROR   = 'METADATA_REQUEST_ERROR'
export const METADATA_REQUEST_SUCCESS = 'METADATA_REQUEST_SUCCESS'
export const METADATA_FETCHING        = 'METADATA_FETCHING'

export const BANNER_REQUEST         = 'BANNER_REQUEST'
export const BANNER_REQUEST_ERROR   = 'BANNER_REQUEST_ERROR'
export const BANNER_REQUEST_SUCCESS = 'BANNER_REQUEST_SUCCESS'
export const BANNER_FETCHING        = 'BANNER_FETCHING'

export const APP_SINGLE_REQUEST         = 'APP_SINGLE_REQUEST'
export const APP_SINGLE_REQUEST_ERROR   = 'APP_SINGLE_REQUEST_ERROR'
export const APP_SINGLE_REQUEST_SUCCESS = 'APP_SINGLE_REQUEST_SUCCESS'
export const APP_SINGLE_FETCHING        = 'APP_SINGLE_FETCHING'

export const APP_REQUEST         = 'APP_REQUEST'
export const APP_REQUEST_ERROR   = 'APP_REQUEST_ERROR'
export const APP_REQUEST_SUCCESS = 'APP_REQUEST_SUCCESS'
export const APP_FETCHING        = 'APP_FETCHING'

export const VOTE         = 'VOTE'
export const VOTE_ERROR   = 'VOTE_ERROR'
export const VOTE_SUCCESS = 'VOTE_SUCCESS'
export const IS_VOTING    = 'IS_VOTING'

export const CREATE_APP         = 'CREATE_APP'
export const CREATE_APP_ERROR   = 'CREATE_APP_ERROR'
export const CREATE_APP_SUCCESS = 'CREATE_APP_SUCCESS'
export const IS_CREATING_APP    = 'IS_CREATING_APP'

export const UPLOAD         = 'UPLOAD'
export const UPLOAD_ERROR   = 'UPLOAD_ERROR'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const IS_UPLOADING   = 'IS_UPLOADING'

export const CLAIM         = 'CLAIM'
export const CLAIM_ERROR   = 'CLAIM_ERROR'
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS'
export const IS_CLAIMING   = 'IS_CLAIMING'

export const SELECT_ITEM         = 'SELECT_ITEM'
export const SELECT_ITEM_ERROR   = 'SELECT_ITEM_ERROR'
export const SELECT_ITEM_SUCCESS = 'SELECT_ITEM_SUCCESS'
export const IS_SELECTING_ITEM   = 'IS_SELECTING_ITEM'

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if(response.error_code == 'no_account') {
          lib.showDownlad()
        }
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}

//Metadata
export const isMetadataFetching = () => {
  return {
    type: METADATA_FETCHING
  }
}

export const metadataRequest = (event) => {
  return (dispatch, getState) => {
    dispatch(isMetadataFetching());
    request('api/metadata/get_infos').then(function(response) {
      if(response.status == 'successful') {
        dispatch(metadataRequestSuccess(response));
      } else {
        dispatch(metadataRequestError(response))
      }
    })
  }
}

export const metadataRequestSuccess = (response) => {
  return {
    type: METADATA_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const metadataRequestError = (response) => {
  return {
    type: METADATA_REQUEST_ERROR,
    payload: response.payload
  }
}

//Banner
export const isBannerFetching = () => {
  return {
    type: BANNER_FETCHING
  }
}

export const bannerRequest = () => {
  return (dispatch, getState) => {
    dispatch(isBannerFetching());
    request('api/banner/get_infos').then(function(response) {
      if(response.status == 'successful') {
        dispatch(bannerRequestSuccess(response));
      } else {
        dispatch(bannerRequestError(response))
      }
    })
  }
}

export const bannerRequestSuccess = (response) => {
  return {
    type: BANNER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const bannerRequestError = (response) => {
  return {
    type: BANNER_REQUEST_ERROR,
    payload: response.payload
  }
}

//App single
export const isAppSingleFetching = () => {
  return {
    type:APP_SINGLE_FETCHING
  }
}

export const appSingleRequest = (accountId) => {
  return (dispatch, getState) => {
    dispatch(isAppSingleFetching())
    request('api/cc_apps/get_infos?account_id=' + accountId).then(function(response) {
      if(response.status == 'successful') {
        dispatch(appSingleRequestSuccess(response));
      } else {
        dispatch(appSingleRequestError(response))
      }
    })
  }
}

export const appSingleRequestSuccess = (response) => {
  return {
    type:APP_SINGLE_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const appSingleRequestError = (response) => {
  return {
    type:APP_SINGLE_REQUEST_ERROR,
    payload: response.payload
  }
}

//App
export const isAppFetching = () => {
  return {
    type: APP_FETCHING
  }
}

export const appRequest = (keywords = '', excludeIds = [], type = 'search') => {
  return (dispatch, getState) => {
    dispatch(isAppFetching())

    const param = {}
    if(type == 'search') {
      if(keywords) {
        param['key'] = keywords
      }
    } else {
      if(keywords) {
        param['key'] = keywords
      }
      if(excludeIds.length) {
        param['except_account_ids'] = excludeIds
      }
    }
    request('api/cc_apps/search', 'POST', {
      body: JSON.stringify(param)
    }).then(function(response) {
      if(response.status == 'successful') {
        if(!response.payload.cc_app_infos.length && type == 'loadmore') {
          lib.showMessage('No videos left')
        }
        dispatch(appRequestSuccess({
          type: type,
          payload: response.payload
        }));
      } else {
        dispatch(appRequestError(response))
      }
    })
  }
}

export const appRequestSuccess = (response) => {
  return {
    type: APP_REQUEST_SUCCESS,
    payload: response
  }
}

export const appRequestError = (response) => {
  return {
    type: APP_REQUEST_ERROR,
    payload: response.payload
  }
}

//Voting
export const isVoting = (response) => {
  return {
    type: IS_VOTING,
  }
}
export const vote = (account_id) => {
  return (dispatch, getState) => {
    dispatch(isVoting());
    request('api/cc_apps/vote', 'POST', {
      body: JSON.stringify({
        account_id: account_id
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(voteSuccess(response))
        lib.showMessage(lng.voteSuccess)
      } else {
        lib.showError(response.error_code)
        dispatch(voteError(response))
      }
    })
  }
}
export const voteSuccess = (response) => {
  return {
    type: VOTE_SUCCESS,
    payload: response.payload
  }
}
export const voteError = (response) => {
  return {
    type: VOTE_ERROR,
    payload: response
  }
}

//Create App
export const isCreatingApp = (response) => {
  return {
    type: IS_CREATING_APP,
  }
}
export const createApp = (data, closeModal = '') => {
  return (dispatch, getState) => {
    dispatch(isCreatingApp());

    const param = {}

    if(data.name) {
      param['name'] = data.name
    }
    if(data.fame_name) {
      param['fame_name'] = data.fame_name
    }
    if(data.about_me) {
      param['about_me'] = data.about_me
    }
    if(data.youtube_channel_url) {
      param['youtube_channel_url'] = data.youtube_channel_url
    }
    if(data.email) {
      param['email'] = data.email
    }
    if(data.phone_number) {
      param['phone_number'] = data.phone_number
    }
    if(data.image) {
      param['image'] = data.image
    }
    if(data.youtube_url) {
      param['youtube_url'] = data.youtube_url
    }
    if(data.stream_url) {
      param['stream_url'] = data.stream_url
    }

    request('api/cc_apps/update_or_create', 'POST', {
      body: JSON.stringify(param)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(createAppSuccess(response))
        lib.showMessage('You have successfully registered!')
        if(closeModal) {
          closeModal()
        }        
      } else {
        lib.showError(response.error_code)
        dispatch(createAppError(response))
      }
    })
  }
}
export const createAppSuccess = (response) => {
  return {
    type: CREATE_APP_SUCCESS,
    payload: response.payload
  }
}
export const createAppError = (response) => {
  return {
    type: CREATE_APP_ERROR,
    payload: response
  }
}

//UPLOAD IMAGE
export const isUploading = (response) => {
  return {
    type: IS_UPLOADING,
  }
}
export const upload = (file, callback) => {
  return (dispatch, getState) => {
    dispatch(isUploading());
    request('api/user/upload_image', 'POST', {
      body: JSON.stringify({
        content: file
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(uploadSuccess(response))
        callback(response.payload.upload_url)
      } else {
        lib.showError(response.payload.error_code)
        dispatch(uploadError(response))
      }
    })
  }
}
export const uploadSuccess = (response) => {
  return {
    type: UPLOAD_SUCCESS,
    payload: response.payload
  }
}
export const uploadError = (response) => {
  return {
    type: UPLOAD_ERROR,
    payload: response.payload
  }
}

//Claim
export const isClaiming = (response) => {
  return {
    type: IS_CLAIMING,
  }
}
export const claim = (reward) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request('api/user/exchange', 'POST', {
      body: JSON.stringify({
        pack_id: parseInt(reward.pack_id)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage('<p class="text-center">Bạn đã nhận được ' + reward.name + 'x' + reward.quantity + '</p>')
        dispatch(claimSuccess(reward.pack_id))
      } else {
        lib.showError(response.payload.error_code)
        dispatch(claimError(response))
      }
    })
  }
}
export const claimSuccess = (response) => {
  return {
    type: CLAIM_SUCCESS,
    payload: response
  }
}
export const claimError = (response) => {
  return {
    type: CLAIM_ERROR,
    payload: response.payload
  }
}

//Select
export const isSelectingItem = (response) => {
  return {
    type: IS_SELECTING_ITEM,
  }
}
export const selectItem = (item) => {
  return (dispatch, getState) => {
    dispatch(isSelectingItem());
    request('api/user/select-item', 'POST', {
      body: JSON.stringify({
        item_id: parseInt(item.id)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(lng.youSelected + ` <span class="highlight">${item.item_name}</span>`)
        dispatch(selectItemSuccess(item))
      } else {
        lib.showError(response.error_code)
        dispatch(selectItemError(response))
      }
    })
  }
}
export const selectItemSuccess = (response) => {
  return {
    type: SELECT_ITEM_SUCCESS,
    payload: response
  }
}
export const selectItemError = (response) => {
  return {
    type: SELECT_ITEM_ERROR,
    payload: response
  }
}
