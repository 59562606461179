import React, { useState } from 'react'
import Modal from 'react-modal'
import Scrollbar from 'react-scrollbars-custom'

import config from 'config/common'
import lib from 'lib/commons'
import Spinner from 'components/Spinner'

Modal.setAppElement('#root');

const ModalRule = ({ modalStatus, closeModal }) => {

  const afterOpenModal = () => {
  }

  const selfClose = (event) => {
    event.preventDefault()
    closeModal()
  }

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="animated fadeInDown faster"
    >
      <div className="modal-description">
        <h2 className="text-gradient" style={{lineHeight: '100%'}}>Frequently<br/>asked questions</h2>

        <div className="text-left">
          <Scrollbar 
              style={ {height: "15vw"} }
              noScrollX={true}>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
          </Scrollbar>
        </div>
      </div>
      <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
        ×
      </a>
    </Modal>
  )
}
export default ModalRule