import config from 'config/app'
import dayjs from 'dayjs'

const convertDateForIos = (date) => {
  var arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  return date;
}

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  METADATA_REQUEST_ERROR,
  METADATA_REQUEST_SUCCESS,
  METADATA_FETCHING,

  BANNER_REQUEST_ERROR,
  BANNER_REQUEST_SUCCESS,
  BANNER_FETCHING,

  APP_REQUEST_ERROR,
  APP_REQUEST_SUCCESS,
  APP_FETCHING,

  APP_SINGLE_REQUEST_ERROR,
  APP_SINGLE_REQUEST_SUCCESS,
  APP_SINGLE_FETCHING,

  VOTE_ERROR,
  VOTE_SUCCESS,
  IS_VOTING,

  UPLOAD_ERROR,
  UPLOAD_SUCCESS,
  IS_UPLOADING,

  CREATE_APP_ERROR,
  CREATE_APP_SUCCESS,
  IS_CREATING_APP,

  CLAIM_ERROR,
  CLAIM_SUCCESS,
  IS_CLAIMING,

} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  items: [],
  banners: [],
  system_time: null,
  delta: 0,
  singleApp: {},
  apps: [],  
  user_reward_infos: [],
  event_start_time: '',
  event_end_time: '',
  cc_app_register_start_time: '',
  cc_app_register_end_time: '',
  cc_app_vote_start_time: '',
  cc_app_vote_end_time: '',
  cc_app_update_data_start_time: '',
  cc_app_update_data_end_time: '',
  apply_google_form: '',
  partners: [],

  isGettingMetadata: false,
  isBannerFetching: false,
  isAppSingleFetching: false,
  isAppFetching: false,
  isVoting: false,
  isCreatingApp: false
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        user_reward_infos: payload.user_reward_infos,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        errorGlobal: 'No info',
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

    //METADATA
    case METADATA_REQUEST_SUCCESS:
      return {
        ...state,
        isGettingMetadata: false,
        delta: new Date(convertDateForIos(payload.system_time)).getTime() - new Date().getTime(),
        system_time: payload.system_time,
        event_start_time: payload.event_start_time,
        event_end_time: payload.event_end_time,
        cc_app_register_start_time: payload.cc_app_register_start_time,
        cc_app_register_end_time: payload.cc_app_register_end_time,
        cc_app_vote_start_time: payload.cc_app_vote_start_time,
        cc_app_vote_end_time: payload.cc_app_vote_end_time,
        cc_app_update_data_start_time: payload.cc_app_update_data_start_time,
        cc_app_update_data_end_time: payload.cc_app_update_data_end_time,
        apply_google_form: payload.apply_google_form,
        partners: [,
          {
            name: payload.partner1_name,
            url: payload.partner1_url,
            content: payload.partner1_content,
            avatar: payload.partner1_avatar
          },
          {
            name: payload.partner2_name,
            url: payload.partner2_url,
            content: payload.partner2_content,
            avatar: payload.partner2_avatar
          },
          {
            name: payload.partner3_name,
            url: payload.partner3_url,
            content: payload.partner3_content,
            avatar: payload.partner3_avatar
          }
        ]
      }
      break;
    case METADATA_REQUEST_ERROR:
      return {
        ...state,
        isGettingMetadata: false,
      }
      break;
    case METADATA_FETCHING:
      return {
        ...state,
        isGettingMetadata: true
      }
      break;

    //BANNERS
    case BANNER_REQUEST_SUCCESS:
      return {
        ...state,
        isBannerFetching: false,
        banners: payload.banner_infos,
      }
      break;
    case BANNER_REQUEST_ERROR:
      return {
        ...state,
        isBannerFetching: false,
      }
      break;
    case BANNER_FETCHING:
      return {
        ...state,
        isBannerFetching: true
      }
      break;

    //APP SINGLE
    case APP_SINGLE_REQUEST_SUCCESS:
      return {
        ...state,
        isAppSingleFetching: false,
        singleApp: payload.cc_app_infos,
      }
      break;
    case APP_SINGLE_REQUEST_ERROR:
      return {
        ...state,
        isAppSingleFetching: false,
      }
      break;
    case APP_SINGLE_FETCHING:
      return {
        ...state,
        isAppSingleFetching: true
      }
      break;

    //APP
    case APP_REQUEST_SUCCESS:
      let appType = payload.type
      let tmpData = payload.payload.cc_app_infos
      let appData = []

      if(appType == 'loadmore') {
        appData = [
          ...state.apps,
          ...tmpData
        ]
      } else if(appType == 'search') {
        appData = tmpData
      }

      return {
        ...state,
        isAppFetching: false,
        apps: appData
      }
      break;
    case APP_REQUEST_ERROR:
      return {
        ...state,
        isAppFetching: false,
      }
      break;
    case APP_FETCHING:
      return {
        ...state,
        isAppFetching: true
      }
      break;

    //VOTE
    case VOTE_SUCCESS:
      return {
        ...state,
        isVoting: false
      }
      break;
    case VOTE_ERROR:
      return {
        ...state,
        isVoting: false
      }
      break;
    case IS_VOTING:
      return {
        ...state,
        isVoting: true
      }
      break;

    //CREATE APP
    case CREATE_APP_SUCCESS:
      return {
        ...state,
        singleApp: payload.cc_app_infos,
        isCreatingApp: false
      }
      break;
    case CREATE_APP_ERROR:
      return {
        ...state,
        isCreatingApp: false
      }
      break;
    case IS_CREATING_APP:
      return {
        ...state,
        isCreatingApp: true
      }
      break;

    //UPLOAD
    case UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false
      }
      break;
    case UPLOAD_ERROR:
      return {
        ...state,
        isUploading: false
      }
      break;
    case IS_UPLOADING:
      return {
        ...state,
        isUploading: true
      }
      break;

    //CLAIM
    case CLAIM_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [payload]: {
            ...state.items[payload],
            exchanged: true
          }
        },
        isClaiming: false
      }
      break;
    case CLAIM_ERROR:
      return {
        ...state,
        isClaiming: false
      }
      break;
    case IS_CLAIMING:
      return {
        ...state,
        isClaiming: true
      }
      break;
  
    default:
      return state
  }
}
