export default {
  en: {
    domain: 'partnerprogrampk.ff.garena.pk',
    requiredField: 'This field is required',
    signIn: 'Login',

    notice: 'Notice',
    confirm: 'Confirm',
    cancel: 'Cancel',
    congrats: 'Gongratulation!!!',
    youReceived: 'You received',
    youReceivedMain: 'You received main prize',
    defaultError: 'Error occurred, please try again later',
    error_params: 'Error Params',
    error_already_voted: 'You have already voted for this post',
    error_already_joined: 'You have already joined',
    error_already_viewed: 'You have already viewed',
    error_already_updated: 'You have already updated',
    error_already_rated: 'You have already rated this post',
    error_already_reported: 'You have already reported this post',
    error_already_claimed: 'You have already claimed',
    error_vote_time_end: 'Vote time has ended',
    error_vote_time_not_start: 'Vote time has not started yet',
    error_story_time_end: 'Story time has ended',
    error_story_time_not_start: 'Story time has not started yet',
    error_cc_app_time_not_start: 'Content creator tour has not started yet',
    error_cc_app_time_end: 'Content creator tour has ended',
    error_not_have_result_yet: 'The result has not come yet',
    error_no_spin_left: 'You do not have enough spin',
    error_reward_not_found: 'No rewards found',
    error_not_enough_fragment: 'You do not have enough fragments',
    error_not_enough_view: 'You do not have enough view',
    error_invalid_file_type: 'File type is invalid',
    error_lock_upload_file: 'Upload file is locked',
  }
}